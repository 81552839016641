import React, { useState } from "react";
import Context from "./index";
import { create, getAll, remove } from "../../Api/AuthApi";
import { message, Form } from "antd";
const AdminOrdersProvider = ({ children, ...props }) => {
  const [adOrdersList, setAdOrdersList] = useState([]);
  const [adOrdersTrackList, setAdOrdersTrackList] = useState([]);
  const [adOrdersListLoader, setAdOrdersListLoader] = useState(false);
  const [adOrdersSingle, setAdOrdersSingle] = useState({});
  const [adOrdersSingleLoader, setAdOrdersSingleLoader] = useState(false);
  const [adOrdersStatusLoader, setAdOrdersStatusLoader] = useState(false);
  const [adOrdersTrackLoader, setAdOrdersTrackLoader] = useState(false);
  const [adCourierTrackLoader, setAdCourierTrackLoader] = useState(false);
  const [adCourierTrackList, setAdCourierTrackList] = useState([]);
  const [adCurrentOrderId, setAdCurrentOrderId] = useState("");
  const [statusPopup, setStatusPopup] = useState(false);
  const [courierPopup, setCourierPopup] = useState(false);
  const [adOrdersDStatusLoader, setAdOrdersDStatusLoader] = useState(false);
  const [adOrdersFilter, setAdOrdersFilter] = useState({
    limit: 10,
    page: 1,
    search: "",
  });
  const [adAddressPrint, setAdAddressPrint] = useState({});
  const [adAddressPrintLoader, setAdAddressPrintLoader] = useState(false);
  const [adAddressPrintPopup, setAdAddressPrintPopup] = useState(false);
  const [adGstPrint, setAdGstPrint] = useState({});
  const [adGstPrintLoader, setAdGstPrintLoader] = useState(false);
  const [adGstPrintPopup, setAdGstPrintPopup] = useState(false);
  const [noteForm] = Form.useForm();
  const [notesLoader, setNotesLoader] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);
  const [verifyForm] = Form.useForm();
  const [invoicePopup, setInvoicePopup] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [pdfDownloadLoader, setPdfDownloadLoader] = useState(false);
  const [pdfDownloadList, setPdfDownloadList] = useState([]);
  const [pdfDownloadPopup, setPdfDownloadPopup] = useState(false);
  const [gstDownloadLoader, setGstDownloadLoader] = useState(false);
  const [gstDownloadList, setGstDownloadList] = useState([]);
  const [gstDownloadPopup, setGstDownloadPopup] = useState(false);
  const [adCourierDropdown, setAdCourierDropdown] = useState([]);
  const [adCourierDropdownLoader, setAdCourierDropdownLoader] = useState(false);
    const [adBrandDropdown, setAdBrandDropdown] = useState([]);
  const [adBrandDropdownLoader, setAdBrandDropdownLoader] = useState(false);
  async function bulkPdfDownload(data) {
    setPdfDownloadLoader(true);
    await create(`admin_orders.php?action=getbulkpdfdownload`, {
      order_id: data,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setPdfDownloadList(res?.data);
          setPdfDownloadLoader(false);
        } else {
          message.error(res?.message);
          setPdfDownloadLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setPdfDownloadLoader(false);
      });
  }
    async function bulkGstDownload(data) {
    setGstDownloadLoader(true);
    await create(`admin_orders.php?action=getbulkgstdownload`, {
      order_id: data,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setGstDownloadList(res?.data);
          setGstDownloadLoader(false);
        } else {
          message.error(res?.message);
          setGstDownloadLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setGstDownloadLoader(false);
      });
  }
  async function verifyPayment(id) {
    setVerifyLoader(true);
    await getAll(
      `payment_callback.php?action=test&payment_id=${id?.payment_id}`
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          message.success(res?.message);
          verifyForm.resetFields();
          setVerifyLoader(false);
        } else {
          message.error(res?.message);
          setVerifyLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setVerifyLoader(false);
      });
  }
  async function notesUpdate(id, data) {
    setNotesLoader(true);
    await create(
      `admin_orders.php?action=addpurchasenotes&order_id=${id}`,
      data
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          message.success(res?.message);
          setNotesLoader(false);
        } else {
          message.error(res?.message);
          setNotesLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setNotesLoader(false);
      });
  }
  async function fetchAdAddressPrint(id, callback = null) {
    setAdAddressPrintLoader(true);
    await getAll(`admin_orders.php?action=orderprint&order_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdAddressPrint(res?.data);
          setAdAddressPrintLoader(false);
          if (callback) callback();
        } else {
          message.error(res?.message);
          setAdAddressPrintLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdAddressPrintLoader(false);
      });
  }

    async function fetchAdGstPrint(id, callback = null) {
    setAdGstPrintLoader(true);
    await getAll(`admin_orders.php?action=gstprint&order_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdGstPrint(res?.data);
          setAdGstPrintLoader(false);
          if (callback) callback();
        } else {
          message.error(res?.message);
          setAdGstPrintLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdGstPrintLoader(false);
      });
  }

  async function fetchAdOrdersList() {
    setAdOrdersListLoader(true);
    await getAll("admin_orders.php?action=orderlist", {
      ...adOrdersFilter,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdOrdersList(res?.data);
          setAdOrdersListLoader(false);
        } else {
          message.error(res?.message);
          setAdOrdersListLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdOrdersListLoader(false);
      });
  }
  async function fetchAdOrdersSingle(id) {
    setAdOrdersSingleLoader(true);
    await getAll(`admin_orders.php?action=orderdetails&order_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdOrdersSingle(res?.data);
          noteForm.setFieldsValue({
            purchase_notes: res?.data?.order?.purchase_notes,
          });
          setAdOrdersSingleLoader(false);
        } else {
          message.error(res?.message);
          setAdOrdersSingleLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdOrdersSingleLoader(false);
      });
  }
  async function fetchAdOrdersSingleBg(id) {
    await getAll(`admin_orders.php?action=orderdetails&order_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdOrdersSingle(res?.data);
        } else {
          message.error(res?.message);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  }

  function formatDateOrder(input) {
    // Parse the input date string
    let date = new Date(input.replace(" ", "T"));

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get individual components
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // Format hours for 12-hour clock
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    // Pad minutes and seconds with leading zeros if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    // Return formatted date string
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds} ${period}`;
  }

  async function updateOrderStatus(id, status) {
    setAdOrdersStatusLoader(true);
    await create(`admin_orders.php?action=orderstatusupdate&order_id=${id}`, {
      order_status: status,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          fetchOrderStatus(id);
          fetchAdOrdersSingleBg(id);
          fetchAdOrdersList();
          message.success(res?.message);
        } else {
          message.error(res?.message);
        }
        setAdOrdersStatusLoader(false);
      })
      .catch((err) => {
        setAdOrdersStatusLoader(false);
        message.error("Something went wrong");
      });
  }


  async function updateBulkOrderStatus(data,from_status,to_status) {
    setAdOrdersListLoader(true);
    await create(`admin_orders.php?action=updatebulkstatus&from_status=${from_status}&to_status=${to_status}`, {
      order_id: data
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdOrdersList(res?.data);
          setAdOrdersListLoader(false);
          message.success(res?.message);
        } else {
          message.error(res?.message);
          setAdOrdersListLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdOrdersListLoader(false);
      });
  }


  async function fetchOrderStatus(id) {
    setAdOrdersTrackLoader(true);
    await getAll(`admin_orders.php?action=ordertrack&order_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdOrdersTrackList(res?.data);
          setAdOrdersTrackLoader(false);
        } else {
          message.error(res?.message);
          setAdOrdersTrackLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdOrdersTrackLoader(false);
      });
  }
  async function deleteOrderStatus(id, status) {
    setAdOrdersDStatusLoader(true);
    await getAll(
      `admin_orders.php?action=orderstatusdelete&order_id=${id}&order_status=${status}`
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          fetchOrderStatus(id);
          fetchAdOrdersSingleBg(id);
          fetchAdOrdersList();
          message.success(res?.message);
        } else {
          message.error(res?.message);
        }
        setAdOrdersDStatusLoader(false);
      })
      .catch((err) => {
        setAdOrdersDStatusLoader(false);
        message.error("Something went wrong");
      });
  }
      async function adCourierDropDownFetch() {
    setAdCourierDropdownLoader(true);
    await getAll("courier_master.php?action=courierdropdown")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCourierDropdown(res?.data);
          setAdCourierDropdownLoader(false);
        } else {
          setAdCourierDropdown([]);
          setAdCourierDropdownLoader(false);
        }
      })
      .catch((err) => {
        setAdCourierDropdown([]);
        setAdCourierDropdownLoader(false);
      });
  }


  async function courierOrderUpdate(orderId, data = {}) {
  setAdOrdersStatusLoader(true);
    const form_data = new FormData();
    form_data.append("courier_id", data?.courier_id);
    form_data.append("courier_track_url", data?.courier_track_url);
    form_data.append("estimatedDateTime", data?.estimatedDateTime);
    form_data.append("tracking_id", data?.tracking_id);
    if(data?.comments){
    form_data.append("comments", data?.comments || "");
    }

    await create(
      "courier_master.php?action=courierorderupdate&order_id=" + orderId,
      form_data
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          fetchOrderStatus(orderId);
          fetchAdOrdersSingleBg(orderId);
          fetchAdOrdersList();
          setAdCurrentOrderId(orderId);
      message.success(res?.message);
        } else {
        message.error(res?.message || "Failed to update the order");
            fetchAdOrdersList();
           setAdOrdersStatusLoader(false);

        }
      })
      .catch((err) => {
        message.error("Something went wrong");
                  fetchAdOrdersList();

       setAdOrdersStatusLoader(false);
      });
  }

  async function fetchCourierData(id) {
    setAdCourierTrackLoader(true);
    await getAll(`courier_master.php?action=couriertracklist&order_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCourierTrackList(res?.data);
          setAdCourierTrackLoader(false);
        } else {
          message.error(res?.message);
          setAdCourierTrackLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdCourierTrackLoader(false);
      });
  }

  return (
    <Context.Provider
      value={{
        ...props,
        adCourierDropdown,
        setAdCourierDropdown,
        adCourierDropdownLoader,
        setAdCourierDropdownLoader,
        adCourierDropDownFetch,
        courierOrderUpdate,
        adCourierTrackLoader,
        setAdCourierTrackLoader,
        setAdCourierTrackList,
        adCourierTrackList,
        fetchCourierData,
        fetchAdOrdersList,
        adOrdersList,
        setAdOrdersList,
        adOrdersListLoader,
        setAdOrdersListLoader,
        adOrdersSingle,
        setAdOrdersSingle,
        adOrdersSingleLoader,
        setAdOrdersSingleLoader,
        adOrdersFilter,
        setAdOrdersFilter,
        formatDateOrder,
        fetchAdOrdersSingle,
        adOrdersTrackList,
        setAdOrdersTrackList,
        adOrdersStatusLoader,
        setAdOrdersStatusLoader,
        adOrdersTrackLoader,
        setAdOrdersTrackLoader,
        adCurrentOrderId,
        setAdCurrentOrderId,
        updateOrderStatus,
        updateBulkOrderStatus,
        // filterOrderStatus,
        fetchOrderStatus,
        statusPopup,
        setStatusPopup,
        courierPopup,
        setCourierPopup,
        adOrdersDStatusLoader,
        setAdOrdersDStatusLoader,
        deleteOrderStatus,
        adAddressPrint,
        setAdAddressPrint,
        adAddressPrintLoader,
        setAdAddressPrintLoader,
        fetchAdAddressPrint,
        adAddressPrintPopup,
        setAdAddressPrintPopup,
        adGstPrint,
        setAdGstPrint,
        adGstPrintLoader,
        setAdGstPrintLoader,
        fetchAdGstPrint,
        adGstPrintPopup,
        setAdGstPrintPopup,
        notesUpdate,
        noteForm,
        notesLoader,
        setNotesLoader,
        verifyLoader,
        setVerifyLoader,
        verifyPayment,
        verifyForm,
        invoicePopup,
        setInvoicePopup,
        pdfData,
        setPdfData,
        bulkPdfDownload,
        pdfDownloadList,
        setPdfDownloadList,
        pdfDownloadLoader,
        setPdfDownloadLoader,
        pdfDownloadPopup,
        setPdfDownloadPopup,
        bulkGstDownload,
        gstDownloadList,
        setGstDownloadList,
        gstDownloadLoader,
        setGstDownloadLoader,
        gstDownloadPopup,
        setGstDownloadPopup,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AdminOrdersProvider;
